import { useMemo, useState } from "react";
import { AppointmentPayment } from "../AppointmentPaymentGrid/types";
import { AppointmentPaymentRefundDialogContainerProps } from "../AppointmentPaymentRefundDialogContainer";
import { Appointment } from "./types";
import { AppointmentRefund } from "../AppointmentRefundGrid/types";

const useAddRefundDialogContainer = (params: {
  appointment: Appointment | null;
  isRefundDue: boolean;
  refunds: AppointmentRefund[];
  currencyCode: string;
  handleSuccess: () => void;
}): {
  props: AppointmentPaymentRefundDialogContainerProps | null;
  handleOpen: (payment: AppointmentPayment) => void;
} => {
  const { refunds, currencyCode, appointment, isRefundDue, handleSuccess } =
    params;

  const [selectedPayment, setSelectedPayment] =
    useState<AppointmentPayment | null>(null);

  const handleOpen = (payment: AppointmentPayment) => {
    setSelectedPayment(payment);
  };

  const onCloseClick = () => setSelectedPayment(null);

  const refundDue = useMemo(() => {
    return isRefundDue ? appointment?.remainingBalance || 0 : 0;
  }, [appointment?.remainingBalance, isRefundDue]);

  const refundLimit = useMemo(() => {
    if (!selectedPayment) {
      return 0;
    }

    const refundedAmount =
      refunds
        ?.filter((refund) => refund.paymentId === selectedPayment?.id)
        .reduce((acc, refund) => acc + refund.amount, 0) || 0;

    return selectedPayment?.amount - refundedAmount;
  }, [refunds, selectedPayment]);

  if (!appointment) {
    return {
      props: null,
      handleOpen: () => {},
    };
  }

  return {
    handleOpen,
    props: selectedPayment
      ? {
          appointmentPaymentId: selectedPayment.id,
          currencyCode,
          refundDue,
          refundLimit,
          isOpen: !!selectedPayment,
          handleClose: onCloseClick,
          handleAddRefund: handleSuccess,
        }
      : null,
  };
};

export default useAddRefundDialogContainer;
