import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translations = useMemo(() => {
    return {
      headerPayment: formatMessage(messages.headerPayment),
      headerReceived: formatMessage(messages.headerReceived),
      headerPaidBy: formatMessage(messages.headerPaidBy),
      methodCard: formatMessage(messages.methodCard),
      methodCash: formatMessage(messages.methodCash),
      btnRefund: formatMessage(messages.btnRefund),
    };
  }, [formatMessage]);

  return translations;
};
export default useTranslations;
