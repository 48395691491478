import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "AppointmentPayment.AppointmentPaymentListCard.title",
    defaultMessage: "Payments",
  },
  btnAdd: {
    id: "AppointmentPayment.AppointmentPaymentListCard.btnAdd",
    defaultMessage: "Add payment",
  },
  btnAdjust: {
    id: "AppointmentPayment.AppointmentPaymentListCard.btnAdjust",
    defaultMessage: "Adjust price",
  },
  totalPrice: {
    id: "AppointmentPayment.AppointmentPaymentListCard.totalPrice",
    defaultMessage: "Total price",
  },
  outstandingBalance: {
    id: "AppointmentPayment.AppointmentPaymentListCard.outstandingBalance",
    defaultMessage: "Outstanding balance",
  },
  refundDue: {
    id: "AppointmentPayment.AppointmentPaymentListCard.refundDue",
    defaultMessage: "Refund due",
  },
  refunds: {
    id: "AppointmentPayment.AppointmentPaymentListCard.refunds",
    defaultMessage: "Refunds",
  },
});
