import { useMemo } from "react";
import { GridColDef } from "../../Unknown/DataGrid";
import getColumnRefundedTo from "./columns/getColumnRefundedTo";
import getColumnRefunded from "./columns/getColumnRefunded";
import getColumnRefund from "./columns/getColumnRefund";
import { AppointmentRefund } from "./types";
import useTranslations from "./useTranslations";
import useStyles from "./useStyles";

const useColumns = (): GridColDef<AppointmentRefund>[] => {
  const translations = useTranslations();
  const { hiddenHeaderSeparatorRoot } = useStyles();

  const columns = useMemo(() => {
    const {
      headerRefund,
      headerRefunded,
      headerRefundedTo,
      methodCard,
      methodCash,
    } = translations;

    return [
      getColumnRefund({
        translations: { headerRefund, methodCash, methodCard },
      }),
      getColumnRefunded({
        translations: { headerRefunded },
      }),
      getColumnRefundedTo({
        translations: { headerRefundedTo },
        headerClassName: hiddenHeaderSeparatorRoot,
      }),
    ];
  }, [hiddenHeaderSeparatorRoot, translations]);

  return columns;
};

export default useColumns;
