import { useMemo } from "react";
import * as yup from "yup";
import useTranslations from "./useTranslations";

const useValidationSchema = ({ maxAmount = 0 }: { maxAmount?: number }) => {
  const { translations } = useTranslations({ maxAmount });

  const validationSchema = useMemo(() => {
    const { maxAmountError } = translations;

    return yup.object().shape({
      amount: yup
        .number()
        .positive(translations.invalidAmount)
        .max(maxAmount, maxAmountError)
        .required(translations.invalidAmount),
    });
  }, [maxAmount, translations]);

  return validationSchema;
};

export default useValidationSchema;
