import React, { FC, useState } from "react";
import { Formik } from "formik";

import AppointmentAdjustPriceDialog from "../AppointmentAdjustPriceDialog";
import { Appointment } from "../AppointmentPaymentListCardContainer/types";
import useTranslations from "./useTranslations";
import useFormikProps from "./useFormikProps";

export type AppointmentAdjustPriceDialogContainerProps = {
  isOpen: boolean;
  currencyCode: string;
  appointment: Appointment | null;
  handleClose: () => void;
  handleSuccess: () => void | Promise<void>;
};

const AppointmentAdjustPriceDialogContainer: FC<
  AppointmentAdjustPriceDialogContainerProps
> = ({ isOpen, appointment, currencyCode, handleClose, handleSuccess }) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { translations } = useTranslations();

  const formikProps = useFormikProps({
    currentPrice: appointment?.totalPrice,
    setErrorMessage,
    handleSuccess,
    handleClose,
  });

  const onDialogClose = () => {
    setErrorMessage(null);
    handleClose();
  };

  return (
    <Formik {...formikProps} enableReinitialize>
      <AppointmentAdjustPriceDialog
        isOpen={isOpen}
        appointment={appointment}
        currencyCode={currencyCode}
        errorMessage={errorMessage}
        formTranslations={translations}
        handleClose={onDialogClose}
      />
    </Formik>
  );
};

export default AppointmentAdjustPriceDialogContainer;
