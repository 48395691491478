import React, { FC } from "react";
import DataGrid from "../../Unknown/DataGrid";
import Box from "../../Unknown/Box";
import { AppointmentRefund } from "./types";
import useColumns from "./useColumns";
import useStyles from "./useStyles";

export type AppointmentRefundGridProps = {
  refunds: AppointmentRefund[];
};

const AppointmentRefundGrid: FC<AppointmentRefundGridProps> = ({ refunds }) => {
  const { root } = useStyles();
  const columns = useColumns();

  return (
    <Box sx={{ width: "100%", display: "grid" }}>
      <DataGrid
        autoHeight
        getRowHeight={() => "auto"}
        className={root}
        columns={columns}
        hideFooter
        hideFooterRowCount
        rows={refunds}
        disableRowSelectionOnClick
        initialState={{
          sorting: { sortModel: [{ field: "confirmedAt", sort: "desc" }] },
        }}
        sortingOrder={["desc", "asc"]}
      />
    </Box>
  );
};

export default AppointmentRefundGrid;
