import { useMemo } from "react";
import { useIntl } from "react-intl";

import commonMessages from "../../../common/messages";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const result = useMemo(() => {
    const translations = {
      defaultError: formatMessage(commonMessages.defaultError),
      type: formatMessage(messages.type),
      amount: formatMessage(messages.amount),
      currentPrice: formatMessage(messages.currentPrice),
      newPrice: formatMessage(messages.newPrice),
      invalidAmount: formatMessage(messages.invalidAmount),
      reasonIsRequired: formatMessage(messages.reasonIsRequired),
      typeIsRequired: formatMessage(messages.typeIsRequired),
      increasedBy: formatMessage(messages.increasedBy),
      decreasedBy: formatMessage(messages.decreasedBy),
      reason: formatMessage(messages.reason),
      totalPrice: formatMessage(messages.totalPrice),
    };

    return { translations };
  }, [formatMessage]);

  return result;
};

export default useTranslations;
