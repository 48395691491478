import { useMemo } from "react";
import { GridColDef } from "../../Unknown/DataGrid";
import getColumnPaidBy from "./columns/getColumnPaidBy";
import getColumnReceivedAt from "./columns/getColumnReceivedAt";
import getColumnPayments from "./columns/getColumnPayments";
import { AppointmentPayment } from "./types";
import useTranslations from "./useTranslations";
import getColumnOptions from "./columns/getColumnOptions";
import useStyles from "./useStyles";

const useColumns = ({
  handleOpenRefundDialog,
}: {
  handleOpenRefundDialog: (payment: AppointmentPayment) => void;
}): GridColDef<AppointmentPayment>[] => {
  const translations = useTranslations();
  const { hiddenHeaderSeparatorRoot } = useStyles();

  const columns = useMemo(() => {
    const {
      headerPayment,
      headerPaidBy,
      headerReceived,
      methodCard,
      methodCash,
      btnRefund,
    } = translations;

    return [
      getColumnPayments({
        translations: { headerPayment, methodCash, methodCard },
      }),
      getColumnReceivedAt({
        translations: { headerReceived },
      }),
      getColumnPaidBy({
        translations: { headerPaidBy },
        headerClassName: hiddenHeaderSeparatorRoot,
      }),
      getColumnOptions({
        translations: { btnRefund },
        headerClassName: hiddenHeaderSeparatorRoot,
        onRefundClick: (payment) => {
          handleOpenRefundDialog(payment);
        },
      }),
    ];
  }, [hiddenHeaderSeparatorRoot, translations, handleOpenRefundDialog]);

  return columns;
};

export default useColumns;
