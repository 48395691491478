import React, { FC, useState } from "react";
import { Formik } from "formik";
import AppointmentPaymentAddDialog from "../AppointmentPaymentAddDialog";
import { Appointment } from "../AppointmentPaymentListCardContainer/types";
import { PaymentOptionsData } from "../AppointmentPaymentAddForm";
import useTranslations from "./useTranslations";
import useFormikProps from "./useFormikProps";

export type AppointmentPaymentAddDialogContainerProps = {
  isOpen: boolean;
  currencyCode: string;
  appointment: Appointment | null;
  paymentOptionsData: PaymentOptionsData;
  isRefundDue: boolean;
  handleClose: () => void;
  handleAddPayment: () => void | Promise<void>;
};

const AppointmentPaymentAddDialogContainer: FC<
  AppointmentPaymentAddDialogContainerProps
> = ({
  isOpen,
  appointment,
  currencyCode,
  paymentOptionsData,
  isRefundDue,
  handleClose,
  handleAddPayment,
}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [stripeClientSecret, setStripeClientSecret] = useState<string | null>(
    null,
  );

  const { translations } = useTranslations();

  const onPaymentIntentCreated = (clientSecret: string) => {
    setStripeClientSecret(clientSecret);
  };

  const formikProps = useFormikProps({
    remainingBalance: appointment?.remainingBalance,
    setErrorMessage,
    handleAddPayment,
    handleClose,
    onPaymentIntentCreated,
  });

  const onStripeElementClose = () => {
    setStripeClientSecret(null);
  };

  const onDialogClose = () => {
    setErrorMessage(null);
    handleClose();
  };

  return (
    <Formik {...formikProps} enableReinitialize>
      <AppointmentPaymentAddDialog
        stripeClientSecret={stripeClientSecret}
        appointment={appointment}
        currencyCode={currencyCode}
        isRefundDue={isRefundDue}
        isOpen={isOpen}
        handleClose={onDialogClose}
        errorMessage={errorMessage}
        formTranslations={translations}
        paymentOptionsData={paymentOptionsData}
        onStripeElementClose={onStripeElementClose}
      />
    </Formik>
  );
};

export default AppointmentPaymentAddDialogContainer;
