import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "AppointmentPayment.AppointmentAdjustPriceDialog.title",
    defaultMessage: "Adjust Appointment Price",
  },
  btnSubmit: {
    id: "AppointmentPayment.AppointmentAdjustPriceDialog.btnSubmit",
    defaultMessage: "Confirm",
  },
  btnCancel: {
    id: "AppointmentPayment.AppointmentAdjustPriceDialog.btnCancel",
    defaultMessage: "Cancel",
  },
});

export default messages;
