import React, { FC, useState } from "react";
import { Formik } from "formik";

import AppointmentPaymentRefundDialog from "../AppointmentPaymentRefundDialog";
import useTranslations from "./useTranslations";
import useFormikProps from "./useFormikProps";

export type AppointmentPaymentRefundDialogContainerProps = {
  isOpen: boolean;
  currencyCode: string;
  appointmentPaymentId: string;
  refundDue: number;
  refundLimit: number;
  handleClose: () => void;
  handleAddRefund: () => void | Promise<void>;
};

const AppointmentPaymentRefundDialogContainer: FC<
  AppointmentPaymentRefundDialogContainerProps
> = ({
  isOpen,
  currencyCode,
  appointmentPaymentId,
  refundDue,
  refundLimit,
  handleClose,
  handleAddRefund,
}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const maxAmount = Math.min(refundDue, refundLimit);

  const { translations } = useTranslations({ maxAmount });

  const formikProps = useFormikProps({
    maxAmount,
    appointmentPaymentId,
    setErrorMessage,
    handleAddRefund,
    handleClose,
  });

  const onDialogClose = () => {
    setErrorMessage(null);
    handleClose();
  };

  return (
    <Formik {...formikProps} enableReinitialize>
      <AppointmentPaymentRefundDialog
        currencyCode={currencyCode}
        refundDue={refundDue}
        refundLimit={refundLimit}
        isOpen={isOpen}
        handleClose={onDialogClose}
        errorMessage={errorMessage}
        formTranslations={translations}
      />
    </Formik>
  );
};

export default AppointmentPaymentRefundDialogContainer;
