import { useState } from "react";

import { AppointmentAdjustPriceDialogContainerProps } from "../AppointmentAdjustPriceDialogContainer";
import { Appointment } from "./types";

const useAdjustPriceDialogContainer = (params: {
  currencyCode: string;
  centerLocale: string;
  appointment: Appointment | null;
  handleSuccess: () => void | Promise<void>;
}): {
  handleOpen: () => void;
  props: AppointmentAdjustPriceDialogContainerProps;
} => {
  const { handleSuccess, appointment, currencyCode } = params;

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return {
    handleOpen,
    props: {
      isOpen,
      appointment,
      currencyCode,
      handleClose,
      handleSuccess,
    },
  };
};

export default useAdjustPriceDialogContainer;
