import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = ({
  totalPriceText,
  newPrice,
}: {
  totalPriceText: React.ReactNode;
  newPrice: React.ReactNode;
}) => {
  const { formatMessage } = useIntl();

  const result = useMemo(() => {
    const translations = {
      alertTitle: formatMessage(messages.alertTitle),
      alertMessage: formatMessage(messages.alertMessage, {
        totalPriceText,
        newPrice,
      }),
    };

    return { translations };
  }, [formatMessage, newPrice, totalPriceText]);

  return result;
};
export default useTranslations;
