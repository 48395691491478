import React, { FC } from "react";
import { useFormikContext } from "formik";

import StatusDialog from "../../Unknown/StatusDialog";
import AppointmentAdjustPriceForm, {
  AppointmentAdjustPriceFormProps,
} from "../AppointmentAdjustPriceForm";
import { FormValues } from "../AppointmentAdjustPriceForm/types";
import { Appointment } from "../AppointmentPaymentListCardContainer/types";
import useActionButtons from "./useActionButtons";
import useTranslations from "./useTranslations";

export type AppointmentAdjustPriceDialogProps = {
  appointment: Appointment | null;
  currencyCode: string;
  formTranslations: AppointmentAdjustPriceFormProps["translations"];
  errorMessage?: string | null;
  isOpen: boolean;
  handleClose: () => void;
};

const AppointmentAdjustPriceDialog: FC<AppointmentAdjustPriceDialogProps> = ({
  appointment,
  isOpen,
  formTranslations,
  errorMessage,
  currencyCode,
  handleClose,
}) => {
  const { translations } = useTranslations();

  const { isSubmitting, resetForm, handleSubmit } =
    useFormikContext<FormValues>();

  const onClose = () => {
    handleClose();
    resetForm();
  };

  const actionButtons = useActionButtons({
    handleClose: onClose,
    handleSubmit,
    isLoading: isSubmitting,
    errorMessage,
  });

  return (
    <StatusDialog
      open={isOpen}
      title={translations.title}
      actionButtons={actionButtons}
      errorMessage={errorMessage}
      isLoading={isSubmitting}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          maxWidth: 800,
        },
      }}
    >
      <AppointmentAdjustPriceForm
        translations={formTranslations}
        appointment={appointment}
        currencyCode={currencyCode}
      />
    </StatusDialog>
  );
};

export default AppointmentAdjustPriceDialog;
