import React, { FC, useEffect } from "react";
import { useFormikContext } from "formik";
import { useIntl } from "react-intl";
import getFormattedPrice from "../../../common/getFormattedPrice";
import CircularProgress from "../../Unknown/CircularProgress";
import FormikTextField from "../../Unknown/FormikTextField";
import Typography from "../../Unknown/Typography";
import MenuItem from "../../Unknown/MenuItem";
import Grid from "../../Unknown/Grid";
import Box from "../../Unknown/Box";
import { Appointment } from "../AppointmentPaymentListCardContainer/types";
import { FormValues, Translations } from "./types";
import useStyles from "./useStyles";
import { names } from "./constants";

export interface PaymentOption {
  id: string;
  centerPaymentMethodId: string;
  label: string;
}

export interface PaymentOptionsData {
  isLoading: boolean;
  errorMessage: string | null;
  paymentOptions: PaymentOption[];
}

export type AppointmentPaymentAddFormProps = {
  appointment: Appointment | null;
  translations: Translations;
  currencyCode: string;
  paymentOptionsData: PaymentOptionsData;
  isRefundDue: boolean;
};

const AppointmentPaymentAddForm: FC<AppointmentPaymentAddFormProps> = ({
  appointment,
  translations,
  currencyCode,
  paymentOptionsData,
  isRefundDue,
}) => {
  const { values, isSubmitting, setFieldValue } =
    useFormikContext<FormValues>();
  const classes = useStyles();
  const intl = useIntl();

  const { isLoading, errorMessage, paymentOptions } = paymentOptionsData;

  useEffect(() => {
    if (values.paymentMethodId) {
      const centerPaymentMethodId = paymentOptions.find(
        (option) => option.id === values.paymentMethodId,
      )?.centerPaymentMethodId;

      setFieldValue("centerPaymentMethodId", centerPaymentMethodId);
    }
  }, [paymentOptions, setFieldValue, values.paymentMethodId]);

  if (!appointment) return null;

  const totalPrice = getFormattedPrice({
    value: appointment.totalPrice,
    currencyCode,
    intl,
  });

  const remainingBalance = getFormattedPrice({
    value: appointment.remainingBalance,
    currencyCode,
    intl,
  });

  const balanceTitle = isRefundDue
    ? translations.refundDue
    : translations.remainingBalance;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <Typography classes={{ root: classes.labelRoot }}>
          {translations.totalPrice}
        </Typography>
        <Typography variant="body2">{totalPrice}</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography classes={{ root: classes.labelRoot }}>
          {balanceTitle}
        </Typography>
        <Typography variant="body2">{remainingBalance}</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormikTextField
          name={names.paymentMethodId}
          label={translations.method}
          select
          required
          fullWidth
          disabled={isSubmitting}
          helperText={errorMessage}
        >
          {isLoading ? (
            <Box display="flex" justifyContent="center" p={4}>
              <CircularProgress />
            </Box>
          ) : (
            paymentOptions.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.label}
              </MenuItem>
            ))
          )}
        </FormikTextField>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormikTextField
          name={names.amount}
          type="number"
          label={translations.amount}
          fullWidth
          disabled={isSubmitting}
          required
        />
      </Grid>
    </Grid>
  );
};

export default AppointmentPaymentAddForm;
