import { useMemo } from "react";
import { useIntl } from "react-intl";
import commonMessages from "../../../common/messages";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const result = useMemo(() => {
    const translations = {
      defaultError: formatMessage(commonMessages.defaultError),
      method: formatMessage(messages.method),
      amount: formatMessage(messages.amount),
      totalPrice: formatMessage(messages.totalPrice),
      remainingBalance: formatMessage(messages.remainingBalance),
      methodIsRequired: formatMessage(messages.methodIsRequired),
      paymentFailed: formatMessage(messages.paymentFailed),
      invalidAmount: formatMessage(messages.invalidAmount),
      timeoutExceeded: formatMessage(messages.timeoutExceeded),
      refundDue: formatMessage(messages.refundDue),
    };

    return { translations };
  }, [formatMessage]);

  return result;
};

export default useTranslations;
