import { useMemo } from "react";
import { useIntl } from "react-intl";
import commonMessages from "../../../common/messages";
import messages from "./messages";

const useTranslations = ({ maxAmount }: { maxAmount?: number }) => {
  const { formatMessage } = useIntl();

  const result = useMemo(() => {
    const translations = {
      defaultError: formatMessage(commonMessages.defaultError),
      amount: formatMessage(messages.amount),
      invalidAmount: formatMessage(messages.invalidAmount),
      refundDue: formatMessage(messages.refundDue),
      maxAmountError: formatMessage(messages.maxAmountError, { maxAmount }),
      refundLimit: formatMessage(messages.refundLimit),
    };

    return { translations };
  }, [formatMessage, maxAmount]);

  return result;
};

export default useTranslations;
