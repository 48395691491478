import React from "react";
import { GridColDef } from "../../../Unknown/DataGrid";
import Typography from "../../../Unknown/Typography";
import Box from "../../../Unknown/Box";
import { AppointmentRefund } from "../types";

type ColumnTranslations = {
  headerRefunded: string;
};

type ColumnParams = {
  translations: ColumnTranslations;
};
const getColumnRefunded = ({
  translations,
}: ColumnParams): GridColDef<AppointmentRefund, Date> => {
  const { headerRefunded } = translations;

  return {
    field: "refundedAt",
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    headerName: headerRefunded,
    renderCell: ({ row }) => {
      const { createdAt, locale } = row;

      if (!createdAt) return null;

      const createdAtDate = createdAt.toLocaleDateString(locale);

      const createdAtTime = createdAt
        .toLocaleTimeString(locale, {
          hour: "numeric",
          minute: "numeric",
        })
        .toLocaleLowerCase()
        .replace(/\s/, "");

      return (
        <Box>
          <Typography variant="body2" color="text.primary">
            {createdAtDate}
          </Typography>
          <Typography variant="body2" fontSize={13} color="text.secondary">
            {createdAtTime}
          </Typography>
        </Box>
      );
    },
  };
};

export default getColumnRefunded;
