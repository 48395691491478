import { defineMessages } from "react-intl";

export default defineMessages({
  amount: {
    id: "AppointmentPayment.AppointmentPaymentRefundDialogContainer.amount",
    defaultMessage: "Amount",
  },
  invalidAmount: {
    id: "AppointmentPayment.AppointmentPaymentRefundDialogContainer.invalidAmount",
    defaultMessage: "Amount must be greater than 0",
  },
  refundDue: {
    id: "AppointmentPayment.AppointmentPaymentRefundDialogContainer.refundDue",
    defaultMessage: "Refund due",
  },
  maxAmountError: {
    id: "AppointmentPayment.AppointmentPaymentRefundDialogContainer.maxAmountError",
    defaultMessage:
      // eslint-disable-next-line no-template-curly-in-string
      "The maximum amount that can be refunded on this payment is ${maxAmount}",
  },
  refundLimit: {
    id: "AppointmentPayment.AppointmentPaymentRefundDialogContainer.refundLimit",
    defaultMessage: "Refund limit for this payment",
  },
});
