import { GridColDef } from "../../../Unknown/DataGrid";
import { AppointmentRefund } from "../types";

type ColumnTranslations = {
  headerRefundedTo: string;
};

const getColumnRefundedTo = (params: {
  translations: ColumnTranslations;
  headerClassName: string;
}): GridColDef<AppointmentRefund, string> => {
  const { translations, headerClassName } = params;

  return {
    field: "customerName",
    disableColumnMenu: true,
    sortable: false,
    headerName: translations.headerRefundedTo,
    flex: 1,
    headerClassName,
  };
};

export default getColumnRefundedTo;
