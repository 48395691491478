import { FormikConfig } from "formik";
import { useParams } from "react-router-dom";
import { useMemo } from "react";

import { PriceAdjustmentType } from "@Shape-Digital/kudzu-data/lib/types/common";

import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import { FormValues } from "../AppointmentAdjustPriceForm/types";
import useValidationSchema from "./useValidationSchema";
import useTranslations from "./useTranslations";

type FormikProps = FormikConfig<FormValues>;

type InitialValues = FormikConfig<FormValues>["initialValues"];
type SubmitHandler = FormikConfig<FormValues>["onSubmit"];

const useFormikProps = (params: {
  currentPrice?: number;
  setErrorMessage: (value: string | null) => void;
  handleSuccess: () => void;
  handleClose: () => void;
}): FormikProps => {
  const { currentPrice, setErrorMessage, handleClose, handleSuccess } = params;

  const { appointmentId } = useParams();

  const { translations } = useTranslations();

  const adjustAppointmentPrice = useFirebaseAppFunction(
    "adjustAppointmentPrice",
  );

  const initialValues = useMemo<InitialValues>(() => {
    return {
      adjustmentType: "",
      reason: "",
      amount: 0,
    };
  }, []);

  const validationSchema = useValidationSchema({ currentPrice });

  const onSubmit: SubmitHandler = async (values, { resetForm }) => {
    try {
      const { adjustmentType, reason, amount } = values;

      if (!appointmentId || !adjustmentType) return;

      await adjustAppointmentPrice({
        type: adjustmentType as PriceAdjustmentType,
        reason,
        amount,
        appointmentId,
      });

      handleSuccess();
      handleClose();
      resetForm();
    } catch (error) {
      const message =
        error instanceof Error ? error.message : translations.defaultError;
      setErrorMessage(message);
    }
  };

  return {
    initialValues,
    validationSchema,
    isInitialValid: false,
    onSubmit,
  };
};

export default useFormikProps;
