import React, { FC } from "react";
import { useIntl } from "react-intl";
import AppointmentPaymentAddDialogContainer from "../AppointmentPaymentAddDialogContainer";
import getFormattedPrice from "../../../common/getFormattedPrice";
import { PaymentOptionsData } from "../AppointmentPaymentAddForm";
import { AppointmentPayment } from "../AppointmentPaymentGrid/types";
import AppointmentPaymentListCard from "../AppointmentPaymentListCard";
import { Appointment } from "./types";
import useAppointmentPaymentAddDialogContainer from "./useAppointmentPaymentAddDialogContainer";
import AppointmentAdjustPriceDialogContainer from "../AppointmentAdjustPriceDialogContainer";
import useAdjustPriceDialogContainer from "./useAdjustPriceDialogContainer";
import AppointmentPaymentRefundDialogContainer from "../AppointmentPaymentRefundDialogContainer";
import useAddRefundDialogContainer from "./useAddRefundDialogContainer";
import { AppointmentRefund } from "../AppointmentRefundGrid/types";

export type AppointmentPaymentListCardContainerProps = {
  payments: AppointmentPayment[];
  refunds: AppointmentRefund[];
  appointment: Appointment | null;
  paymentOptionsData: PaymentOptionsData;
  currencyCode: string;
  centerLocale: string;
  isRefundDue: boolean;
  handleAddPayment: () => void;
};

const AppointmentPaymentListCardContainer: FC<
  AppointmentPaymentListCardContainerProps
> = ({
  payments,
  refunds,
  appointment,
  currencyCode,
  centerLocale,
  isRefundDue,
  paymentOptionsData,
  handleAddPayment,
}) => {
  const { handleOpen: handleAddPaymentDialogOpen, props: addPaymentProps } =
    useAppointmentPaymentAddDialogContainer({
      appointment,
      currencyCode,
      centerLocale,
      paymentOptionsData,
      isRefundDue,
      handleAddPayment,
    });

  const { handleOpen: handleAdjustPriceDialogOpen, props: adjustPriceProps } =
    useAdjustPriceDialogContainer({
      appointment,
      currencyCode,
      centerLocale,
      handleSuccess: handleAddPayment,
    });

  const { props: addRefundProps, handleOpen: handleOpenRefundDialog } =
    useAddRefundDialogContainer({
      refunds,
      currencyCode,
      appointment,
      isRefundDue,
      handleSuccess: handleAddPayment,
    });

  const intl = useIntl();

  const isPaymentButtonShown = !!appointment?.remainingBalance && !isRefundDue;

  const totalPrice = getFormattedPrice({
    value: appointment?.totalPrice || 0,
    currencyCode,
    intl,
  });

  const remainingBalance = getFormattedPrice({
    value: appointment?.remainingBalance || 0,
    currencyCode,
    intl,
  });

  return (
    <>
      <AppointmentPaymentListCard
        payments={payments}
        refunds={refunds}
        totalPrice={totalPrice}
        remainingBalance={remainingBalance}
        isRefundDue={isRefundDue}
        isPaymentButtonShown={isPaymentButtonShown}
        handleAddClick={handleAddPaymentDialogOpen}
        handleAdjustPriceClick={handleAdjustPriceDialogOpen}
        handleOpenRefundDialog={handleOpenRefundDialog}
      />
      <AppointmentPaymentAddDialogContainer {...addPaymentProps} />
      {addRefundProps && (
        <AppointmentPaymentRefundDialogContainer {...addRefundProps} />
      )}
      <AppointmentAdjustPriceDialogContainer {...adjustPriceProps} />
    </>
  );
};

export default AppointmentPaymentListCardContainer;
