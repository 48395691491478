import React, { FC } from "react";

import { useParams } from "react-router-dom";
import { DatabaseEnum } from "@Shape-Digital/kudzu-data/lib/types/common";
import Button from "../../Unknown/Button";
import Card from "../../Unknown/Card";
import CardContent from "../../Unknown/CardContent";
import CardHeader from "../../Unknown/CardHeader";
import Box from "../../Unknown/Box";
import Grid from "../../Unknown/Grid";
import Typography from "../../Unknown/Typography";
import AppointmentPaymentGrid from "../AppointmentPaymentGrid";
import { AppointmentPayment } from "../AppointmentPaymentGrid/types";
import useStyles from "./useStyles";
import useTranslations from "./useTranslations";
import AppointmentRefundGrid from "../AppointmentRefundGrid";
import { AppointmentRefund } from "../AppointmentRefundGrid/types";

export type AppointmentPaymentListCardProps = {
  isPaymentButtonShown: boolean;
  remainingBalance: string;
  totalPrice: string;
  payments: AppointmentPayment[];
  refunds: AppointmentRefund[];
  isRefundDue: boolean;
  handleAddClick: () => void;
  handleAdjustPriceClick: () => void;
  handleOpenRefundDialog: (payment: AppointmentPayment) => void;
};

const AppointmentPaymentListCard: FC<AppointmentPaymentListCardProps> = ({
  isPaymentButtonShown,
  totalPrice,
  remainingBalance,
  payments,
  refunds,
  isRefundDue,
  handleAddClick,
  handleAdjustPriceClick,
  handleOpenRefundDialog,
}) => {
  const { translations } = useTranslations();
  const classes = useStyles();
  const { title, btnAdd, btnAdjust } = translations;
  const { role } = useParams<{ role: DatabaseEnum["center_user_role"] }>();

  const isAdjustButtonShown =
    !!role &&
    ["receptionist", "regional_admin", "super_admin", "technologist"].includes(
      role,
    );

  const balanceTitle = isRefundDue
    ? translations.refundDue
    : translations.outstandingBalance;

  return (
    <Card sx={{ minHeight: 250 }}>
      <CardHeader
        title={title}
        action={
          <Box display="flex" gap={3}>
            {!!isAdjustButtonShown && (
              <Button variant="contained" onClick={handleAdjustPriceClick}>
                {btnAdjust}
              </Button>
            )}
          </Box>
        }
      />
      <CardContent sx={{ pt: 0 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography classes={{ root: classes.labelRoot }}>
              {translations.totalPrice}
            </Typography>
            <Typography variant="body2">{totalPrice}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography classes={{ root: classes.labelRoot }}>
              {balanceTitle}
            </Typography>
            <Typography variant="body2">{remainingBalance}</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography classes={{ root: classes.labelRoot }}>
              {translations.title}
            </Typography>
            {!!isPaymentButtonShown && (
              <Button variant="contained" size="small" onClick={handleAddClick}>
                {btnAdd}
              </Button>
            )}
          </Grid>
          <Grid item xs={12} mt={-4}>
            <AppointmentPaymentGrid
              payments={payments}
              handleOpenRefundDialog={handleOpenRefundDialog}
            />
          </Grid>
          {!!refunds.length && (
            <>
              <Grid item xs={12} mt={4}>
                <Typography classes={{ root: classes.labelRoot }}>
                  {translations.refunds}
                </Typography>
              </Grid>
              <Grid item xs={12} mt={-4}>
                <AppointmentRefundGrid refunds={refunds} />
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AppointmentPaymentListCard;
