import { defineMessages } from "react-intl";

const messages = defineMessages({
  headerPayment: {
    id: "AppointmentPayment.AppointmentPaymentGrid.headerPayment",
    defaultMessage: "Payment",
  },
  headerReceived: {
    id: "AppointmentPayment.AppointmentPaymentGrid.headerReceived",
    defaultMessage: "Received",
  },
  headerPaidBy: {
    id: "AppointmentPayment.AppointmentPaymentGrid.headerPaidBy",
    defaultMessage: "Paid by",
  },
  methodCard: {
    id: "AppointmentPayment.AppointmentPaymentGrid.methodCard",
    defaultMessage: "Card",
  },
  methodCash: {
    id: "AppointmentPayment.AppointmentPaymentGrid.methodCash",
    defaultMessage: "Cash",
  },
  btnRefund: {
    id: "AppointmentPayment.AppointmentPaymentGrid.btnRefund",
    defaultMessage: "Refund",
  },
});

export default messages;
