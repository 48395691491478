import React, { FC } from "react";
import { useFormikContext } from "formik";

import StatusDialog from "../../Unknown/StatusDialog";
import { FormValues } from "../AppointmentPaymentAddForm/types";
import AppointmentPaymentRefundForm, {
  AppointmentPaymentRefundFormProps,
} from "../AppointmentPaymentRefundForm";
import useActionButtons from "./useActionButtons";
import useTranslations from "./useTranslations";

export type AppointmentPaymentAddDialogProps = {
  currencyCode: string;
  formTranslations: AppointmentPaymentRefundFormProps["translations"];
  errorMessage?: string | null;
  isOpen: boolean;
  refundDue: number;
  refundLimit: number;
  handleClose: () => void;
};

const AppointmentPaymentAddDialog: FC<AppointmentPaymentAddDialogProps> = ({
  isOpen,
  formTranslations,
  errorMessage,
  currencyCode,
  refundDue,
  refundLimit,
  handleClose,
}) => {
  const { translations } = useTranslations();

  const { isSubmitting, resetForm, handleSubmit } =
    useFormikContext<FormValues>();

  const onClose = () => {
    handleClose();
    resetForm();
  };

  const actionButtons = useActionButtons({
    handleClose: onClose,
    handleSubmit,
    isLoading: isSubmitting,
    errorMessage,
  });

  return (
    <StatusDialog
      open={isOpen}
      title={translations.title}
      actionButtons={actionButtons}
      errorMessage={errorMessage}
      isLoading={isSubmitting}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          maxWidth: 800,
        },
      }}
    >
      <AppointmentPaymentRefundForm
        translations={formTranslations}
        currencyCode={currencyCode}
        refundDue={refundDue}
        refundLimit={refundLimit}
      />
    </StatusDialog>
  );
};

export default AppointmentPaymentAddDialog;
