import { defineMessages } from "react-intl";

const messages = defineMessages({
  alertTitle: {
    id: "AppointmentPayment.AppointmentAdjustPriceForm.alertTitle",
    defaultMessage: "Review price adjustment before saving",
  },
  alertMessage: {
    id: "AppointmentPayment.AppointmentAdjustPriceForm.alertMessage",
    defaultMessage:
      "You are changing the {totalPriceText} of this appointment (including extras) to {newPrice}",
  },
});

export default messages;
