import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "AppointmentPayment.AppointmentRefundAddDialog.title",
    defaultMessage: "Add Refund",
  },
  btnSubmit: {
    id: "AppointmentPayment.AppointmentRefundAddDialog.btnSubmit",
    defaultMessage: "Confirm refund",
  },
  btnCancel: {
    id: "AppointmentPayment.AppointmentRefundAddDialog.btnCancel",
    defaultMessage: "Cancel",
  },
});

export default messages;
