import { useIntl } from "react-intl";
import { AppointmentAuditLogEvent } from "@Shape-Digital/kudzu-data/lib/constants/common";
import messages from "./messages";

const useTranslations = (): Record<AppointmentAuditLogEvent, string> & {
  systemTitle: string;
} => {
  const { formatMessage } = useIntl();

  return {
    systemTitle: formatMessage(messages.system),
    filesIsAdded: formatMessage(messages.addedFilesToAppointment),
    markedAsPriority: formatMessage(messages.appointmentMarkedAsPriority),
    offeringChanged: formatMessage(messages.offeringChanged),
    paymentAdded: formatMessage(messages.addedPayment),
    reportMarkedAsAdditionalVies: formatMessage(
      messages.studyMarkedAsAdditionalViewRequired,
    ),
    reportMarkedAsUnexpectedFindings: formatMessage(
      messages.reportMarkedAsUnexpectedFindings,
    ),
    reportMarkedAsUnexpectedFindingsConfirmed: formatMessage(
      messages.reportMarkedAsUnexpectedFindingsConfirmed,
    ),
    reportResentManually: formatMessage(messages.reportsSentToPatient),
    reportSentBySystem: formatMessage(messages.reportsSentToPatient),
    rescheduled: formatMessage(messages.appointmentRescheduled),
    statusChangedFromIncompleteToConfirmed: formatMessage(
      messages.confirmedIncompleteAppointment,
    ),
    statusChangedToCancel: formatMessage(messages.appointmentCanceled),
    statusChangedToCheckedIn: formatMessage(messages.checkedInPatient),
    statusChangedToNoShow: formatMessage(messages.appointmentMarkedAsNoShow),
    patientLoggedInManually: formatMessage(messages.loggedInPatient),
    refundAdded: formatMessage(messages.refundAdded),
    appointmentPriceChanged: formatMessage(messages.appointmentPriceChanged),
  };
};
export default useTranslations;
