import { alpha, createTheme, Theme, ThemeOptions } from "@mui/material";

interface BorderRadius {
  s: number;
  m: number;
}
export interface ITheme extends Theme {
  borderRadius: BorderRadius;
}

interface IThemeOptions extends ThemeOptions {
  borderRadius: BorderRadius;
}

const breakpoints = {
  values: { xs: 0, sm: 540, md: 760, lg: 960, xl: 1200 },
};

const colors = {
  grey: "#EEEEEE",
  black: "#000000",
};

const borderRadius = {
  s: 4,
  m: 8,
};

const defaultTheme = createTheme({
  spacing: 5,
  breakpoints,
  shape: { borderRadius: 4 },
});

const theme = createTheme({
  spacing: 5,
  breakpoints,
  borderRadius,
  shape: { borderRadius: 4 },
  palette: {
    grey: { 100: colors.grey },
    alpha: {
      25: alpha(colors.black, 0.25),
    },
    background: {
      default: colors.grey,
    },
    text: {
      primary: alpha(defaultTheme.palette.text.primary, 0.87),
    },
  },
  typography: {
    fontFamily: "Roboto, Sans-serif",
    h2: {
      fontSize: 24,
      fontWeight: 400,
      lineHeight: 1.33,
    },
    h3: {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: 1.6,
    },
    h4: {
      fontSize: 34,
      fontWeight: 400,
      lineHeight: 1.33,
    },
    h5: {
      fontSize: 24,
      fontWeight: 400,
      lineHeight: 1.66,
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.66,
    },
    subtitle1: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1.5,
    },
    subtitle2: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1,
      letterSpacing: 0.15,
      color: defaultTheme.palette.text.secondary,
    },
    overline: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 5,
      letterSpacing: 1,
      color: "black",
      fontStyle: "normal",
    },
    button: { letterSpacing: 0.5 },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          whiteSpace: "nowrap",
          minWidth: "max-content",
        },
        sizeMedium: {
          paddingTop: defaultTheme.spacing(1.5),
          paddingRight: defaultTheme.spacing(4.5),
          paddingBottom: defaultTheme.spacing(1.5),
          paddingLeft: defaultTheme.spacing(4.5),
          fontSize: 14,
        },
        sizeSmall: {
          paddingTop: defaultTheme.spacing(0.8),
          paddingRight: defaultTheme.spacing(1),
          paddingBottom: defaultTheme.spacing(0.8),
          paddingLeft: defaultTheme.spacing(1),
          fontSize: 13,
        },
      },
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          [defaultTheme.breakpoints.up("sm")]: {
            paddingLeft: defaultTheme.spacing(6),
            paddingRight: defaultTheme.spacing(6),
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        message: {
          fontSize: 14,
          fontWeight: 400,
          lineHeight: 1.66,
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiTextField: {
      defaultProps: {
        autoComplete: "off",
      },
    },
    MuiDialogTitle: {
      defaultProps: {
        variant: "h5",
      },
      styleOverrides: {
        root: {
          padding: defaultTheme.spacing(3),
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: defaultTheme.spacing(3),
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          paddingTop: defaultTheme.spacing(2),
          paddingRight: defaultTheme.spacing(3),
          paddingBottom: defaultTheme.spacing(2),
          paddingLeft: defaultTheme.spacing(3),
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        action: {
          margin: 0,
          alignSelf: "center",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          "&:last-child": {
            paddingBottom: defaultTheme.spacing(3),
          },
          padding: defaultTheme.spacing(3),
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          paddingTop: defaultTheme.spacing(1.5),
          paddingRight: defaultTheme.spacing(3),
          paddingBottom: defaultTheme.spacing(1.5),
          paddingLeft: defaultTheme.spacing(3),
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltipPlacementBottom: {
          top: -6,
        },
      },
    },
  },
} as IThemeOptions) as ITheme;

export default theme;
