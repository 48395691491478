import { defineMessages } from "react-intl";

export default defineMessages({
  type: {
    id: "AppointmentPayment.AppointmentPaymentAdjustPriceContainer.type",
    defaultMessage: "Adjustment type",
  },
  amount: {
    id: "AppointmentPayment.AppointmentPaymentAdjustPriceContainer.amount",
    defaultMessage: "Amount",
  },
  reason: {
    id: "AppointmentPayment.AppointmentPaymentAdjustPriceContainer.reason",
    defaultMessage: "Reason",
  },
  currentPrice: {
    id: "AppointmentPayment.AppointmentPaymentAdjustPriceContainer.currentPrice",
    defaultMessage: "Current Price",
  },
  newPrice: {
    id: "AppointmentPayment.AppointmentPaymentAdjustPriceContainer.newPrice",
    defaultMessage: "New Price",
  },
  invalidAmount: {
    id: "AppointmentPayment.AppointmentPaymentAdjustPriceContainer.invalidAmount",
    defaultMessage:
      "Amount must be greater than 0 and less than the current price",
  },
  reasonIsRequired: {
    id: "AppointmentPayment.AppointmentPaymentAdjustPriceContainer.reasonIsRequired",
    defaultMessage: "Reason is required",
  },
  typeIsRequired: {
    id: "AppointmentPayment.AppointmentPaymentAdjustPriceContainer.typeIsRequired",
    defaultMessage: "Adjustment type is required",
  },
  increasedBy: {
    id: "AppointmentPayment.AppointmentPaymentAdjustPriceContainer.increasedBy",
    defaultMessage: "Increase price by",
  },
  decreasedBy: {
    id: "AppointmentPayment.AppointmentPaymentAdjustPriceContainer.decreasedBy",
    defaultMessage: "Decrease price by",
  },
  totalPrice: {
    id: "AppointmentPayment.AppointmentPaymentAdjustPriceContainer.totalPrice",
    defaultMessage: "total price",
  },
});
