import React from "react";
import { GridColDef } from "../../../Unknown/DataGrid";
import { AppointmentPayment } from "../types";
import SimpleOptionsMenu from "../../../Unknown/SimpleOptionsMenu";
import Box from "../../../Unknown/Box";
import getOptionalArrayItem from "../../../../common/getOptionalArrayItem";

type ColumnValue = null;

type ColumnTranslations = {
  btnRefund: string;
};

const getColumnOptions = (params: {
  translations: ColumnTranslations;
  headerClassName: string;
  role?: string;
  onRefundClick: (payment: AppointmentPayment) => void;
}): GridColDef<AppointmentPayment, ColumnValue> => {
  const { translations, headerClassName, role, onRefundClick } = params;
  const { btnRefund } = translations;

  return {
    field: "options",
    disableColumnMenu: true,
    headerClassName,
    headerName: "",
    sortable: false,
    flex: 0.2,
    maxWidth: 80,
    renderCell: (renderParams) => {
      return (
        <Box display="flex" justifyContent="center" width="100%">
          <SimpleOptionsMenu
            options={[
              ...getOptionalArrayItem(role !== "radiologist", {
                id: "refund",
                text: btnRefund,
                onClick: () => onRefundClick(renderParams.row),
              }),
            ]}
          />
        </Box>
      );
    },
  };
};

export default getColumnOptions;
