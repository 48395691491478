import Big from "big.js";
import { PriceAdjustmentType } from "@Shape-Digital/kudzu-data/lib/types/common";

interface GetAdjustedPriceParams {
  price: number;
  amount: number;
  type: string;
}

export const getAdjustedPrice = ({
  price,
  amount,
  type,
}: GetAdjustedPriceParams) => {
  const priceBig = new Big(price);

  switch (type) {
    case PriceAdjustmentType.Increased:
      return priceBig.plus(amount).toNumber();

    case PriceAdjustmentType.Decreased:
      return priceBig.minus(amount).toNumber();

    default:
      return price;
  }
};
