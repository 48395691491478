import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translations = useMemo(() => {
    return {
      headerRefund: formatMessage(messages.headerRefund),
      headerRefunded: formatMessage(messages.headerRefunded),
      headerRefundedTo: formatMessage(messages.headerRefundedTo),
      methodCard: formatMessage(messages.methodCard),
      methodCash: formatMessage(messages.methodCash),
      btnRefund: formatMessage(messages.btnRefund),
    };
  }, [formatMessage]);

  return translations;
};
export default useTranslations;
