import React, { FC, useMemo } from "react";
import { useFormikContext } from "formik";
import { useIntl } from "react-intl";

import { PriceAdjustmentType } from "@Shape-Digital/kudzu-data/lib/types/common";

import { getAdjustedPrice } from "../../../common/getAdjustedPrice";
import getFormattedPrice from "../../../common/getFormattedPrice";
import FormikTextField from "../../Unknown/FormikTextField";
import Typography from "../../Unknown/Typography";
import MenuItem from "../../Unknown/MenuItem";
import Alert from "../../Unknown/Alert";
import Grid from "../../Unknown/Grid";
import AlertTitle from "../../Unknown/AlertTitle";
import { Appointment } from "../AppointmentPaymentListCardContainer/types";
import { FormValues, Translations } from "./types";
import useStyles from "./useStyles";
import { names } from "./constants";
import useTranslations from "./useTranslations";

export type AppointmentAdjustPriceFormProps = {
  appointment: Appointment | null;
  translations: Translations;
  currencyCode: string;
};

const AppointmentAdjustPriceForm: FC<AppointmentAdjustPriceFormProps> = ({
  appointment,
  translations,
  currencyCode,
}) => {
  const { values, isSubmitting, errors } = useFormikContext<FormValues>();
  const classes = useStyles();
  const intl = useIntl();

  const { formattedTotalPrice, formattedNewPrice, newPrice } = useMemo(() => {
    if (!appointment) {
      return { formattedTotalPrice: "", formattedNewPrice: "" };
    }

    const totalPriceLabel = getFormattedPrice({
      value: appointment.totalPrice,
      currencyCode,
      intl,
    });

    const adjustedPrice = values.adjustmentType
      ? getAdjustedPrice({
          price: appointment?.totalPrice,
          type: values.adjustmentType,
          amount: values.amount || 0,
        })
      : appointment.totalPrice;

    const newPriceLabel = getFormattedPrice({
      value: adjustedPrice,
      currencyCode,
      intl,
    });

    return {
      formattedNewPrice: newPriceLabel,
      formattedTotalPrice: totalPriceLabel,
      newPrice: adjustedPrice,
    };
  }, [appointment, currencyCode, intl, values.adjustmentType, values.amount]);

  const { translations: formTranslations } = useTranslations({
    totalPriceText: (
      <Typography display="inline" fontWeight={700} variant="body2">
        {translations.totalPrice}
      </Typography>
    ),
    newPrice: (
      <Typography display="inline" fontWeight={700} variant="body2">
        ${newPrice}
      </Typography>
    ),
  });

  const typeOptions = [
    {
      label: translations.increasedBy,
      value: PriceAdjustmentType.Increased,
    },
    {
      label: translations.decreasedBy,
      value: PriceAdjustmentType.Decreased,
    },
  ];

  if (!appointment) return null;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <Typography classes={{ root: classes.labelRoot }}>
          {translations.currentPrice}
        </Typography>
        <Typography variant="body2">{formattedTotalPrice}</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography classes={{ root: classes.labelRoot }}>
          {translations.newPrice}
        </Typography>
        <Typography variant="body2">{formattedNewPrice}</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormikTextField
          name={names.adjustmentType}
          label={translations.type}
          select
          required
          fullWidth
          disabled={isSubmitting}
        >
          {typeOptions.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </FormikTextField>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormikTextField
          name={names.amount}
          type="number"
          label={translations.amount}
          fullWidth
          disabled={isSubmitting}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <FormikTextField
          name={names.reason}
          label={translations.reason}
          fullWidth
          disabled={isSubmitting}
          required
        />
      </Grid>
      {!!(values.adjustmentType && values.amount && !errors.amount) && (
        <Grid item xs={12}>
          <Alert severity="warning">
            <AlertTitle>{formTranslations.alertTitle}</AlertTitle>
            {formTranslations.alertMessage}
          </Alert>
        </Grid>
      )}
    </Grid>
  );
};

export default AppointmentAdjustPriceForm;
