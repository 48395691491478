import { useMemo } from "react";
import { useIntl } from "react-intl";
import { AppointmentPaymentListCardContainerProps } from "../../AppointmentPayment/AppointmentPaymentListCardContainer";
import calcRemainingBalance from "../../AppointmentPayment/AppointmentPaymentListCardContainer/calcRemainingBalance";
import {
  DEFAULT_CURRENCY_CODE,
  DEFAULT_LOCALE,
} from "../../../common/constants";
import useSystemConfig from "../../../hooks/useSystemConfig";
import usePaymentOptions from "./usePaymentOptions";
import { AppointmentPayment } from "../../AppointmentPayment/AppointmentPaymentGrid/types";
import { convertDatabaseDateToTimezone } from "../../../common/dateHelpers";
import { Appointment } from "./types";
import calcTotalPrice from "../../../common/calcTotalPrice";
import { AppointmentRefund } from "../../AppointmentPayment/AppointmentRefundGrid/types";

const useAppointmentPaymentsContainer = ({
  appointment,
  refetch,
}: {
  appointment: Appointment | null;
  refetch: () => void;
}): AppointmentPaymentListCardContainerProps => {
  const systemConfig = useSystemConfig();
  const intl = useIntl();

  const paymentOptionsData = usePaymentOptions(appointment);

  const props = useMemo<AppointmentPaymentListCardContainerProps>(() => {
    const totalPrice = appointment
      ? calcTotalPrice({
          totalCostAmount: appointment.total_cost_amount,
          discountAmount: appointment.discount_amount,
        })
      : 0;

    const remainingBalance = appointment
      ? calcRemainingBalance(appointment)
      : 0;

    const appointmentData = appointment
      ? {
          id: appointment.id,
          totalPrice,
          remainingBalance: Math.abs(remainingBalance),
        }
      : null;

    const locale = appointment?.center.region || DEFAULT_LOCALE;

    const currencyCode =
      appointment?.center?.center_configs[0]?.default_currency_code ||
      systemConfig.dataState.data?.default_currency_code ||
      DEFAULT_CURRENCY_CODE;

    const payments =
      appointment?.appointment_payments.map((payment): AppointmentPayment => {
        const confirmedAt = payment.confirmed_at
          ? convertDatabaseDateToTimezone(
              payment.confirmed_at,
              appointment.center.timezone,
            )
          : null;

        return {
          id: payment.id,
          customerName: payment.customer_name || "",
          amount: payment.amount || 0,
          currencyCode,
          locale,
          intl,
          confirmedAt: confirmedAt?.dateTimezone || null,
          method: payment.center_payment_method?.payment_method?.type,
          paymentMethodLabel: payment.payment_method_label || undefined,
        };
      }) || [];

    const refunds =
      appointment?.appointment_refunds.map((refund): AppointmentRefund => {
        return {
          id: refund.id.toString(),
          amount: refund.amount || 0,
          currencyCode,
          createdAt: refund?.created_at
            ? convertDatabaseDateToTimezone(
                refund?.created_at,
                appointment.center?.timezone,
              )?.dateTimezone
            : null,
          customerName: refund.customer_name || "",
          paymentId: refund.appointment_payment_id,
          method: refund.center_payment_method?.payment_method?.type,
          locale,
          intl,
          paymentMethodLabel: refund.payment_method_label || undefined,
        };
      }) || [];

    return {
      appointment: appointmentData,
      isRefundDue: remainingBalance < 0,
      currencyCode,
      centerLocale: appointment?.center.region || DEFAULT_LOCALE,
      paymentOptionsData,
      payments,
      refunds,
      handleAddPayment: refetch,
    };
  }, [
    appointment,
    intl,
    paymentOptionsData,
    refetch,
    systemConfig.dataState.data?.default_currency_code,
  ]);

  return props;
};

export default useAppointmentPaymentsContainer;
