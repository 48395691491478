import { httpsCallable } from "firebase/functions";
import { useFunctions } from "reactfire";
import { useMemo } from "react";
import {
  CreateCustomCenterUserTokenParams,
  CreateCustomCenterUserTokenResponse,
  AddCenterTimeSlotParams,
  AddCenterTimeSlotResponse,
  EditCenterTimeSlotParams,
  EditCenterTimeSlotResponse,
  DeleteCenterTimeSlotParams,
  DeleteCenterTimeSlotResponse,
  UpdateLastUsedRoleParams,
  UpdateLastUsedRoleResponse,
  CancelAppointmentParams,
  CancelAppointmentResponse,
  RescheduleAppointmentParams,
  RescheduleAppointmentResponse,
  CreateAppointmentNoteParams,
  CreateAppointmentNoteResponse,
  UpdateCenterPatientParams,
  UpdateCenterPatientResponse,
  UpdateCenterAddressParams,
  UpdateCenterAddressResponse,
  UpdateCenterContactInformationParams,
  UpdateCenterContactInformationResponse,
  UpdateCenterOperatingHoursParams,
  UpdateCenterOperatingHoursResponse,
  UpdateCenterDiscountParams,
  UpdateCenterDiscountResponse,
  UpdateCenterOfferingParams,
  UpdateCenterOfferingResponse,
  AddCenterOfferingParams,
  AddCenterOfferingResponse,
  AddCenterAddOnParams,
  AddCenterAddOnResponse,
  UpdateCenterAddOnParams,
  UpdateCenterAddOnResponse,
  ArchiveCenterOfferingParams,
  ArchiveCenterOfferingResponse,
  ArchiveCenterAddOnParams,
  ArchiveCenterAddOnResponse,
  AddAppointmentFilesParams,
  AddAppointmentFilesResponse,
  SendCodePatientToUpdateEmailParams,
  SendCodePatientToUpdateEmailResponse,
  AddCenterPackageParams,
  AddCenterPackageResponse,
  UpdateCenterPackageParams,
  UpdateCenterPackageResponse,
  UpdateAppointmentOfferingParams,
  UpdateAppointmentOfferingResponse,
  MarkAppointmentOfferingAdditionalViewParams,
  MarkAppointmentOfferingAdditionalViewResponse,
  ArchiveCenterPackageParams,
  ArchiveCenterPackageResponse,
  MarkAppointmentPriorityParams,
  MarkAppointmentPriorityResponse,
  MarkAppointmentAsNoShowParams,
  MarkAppointmentAsNoShowResponse,
  GetCustomerPaymentMethodsResponse,
  GetCustomerPaymentMethodsParams,
  BookAdditionalViewAppointmentParams,
  BookAdditionalViewAppointmentResponse,
  PayAppointmentRemainingBalanceParams,
  PayAppointmentRemainingBalanceResponse,
  UpdateCenterCustomerParams,
  UpdateCenterCustomerResponse,
  SendCodeCustomerToUpdateEmailResponse,
  SendCodeCustomerToUpdateEmailParams,
  ProvideCenterAppAppointmentScreeningFormParams,
  ProvideCenterAppAppointmentScreeningFormResponse,
  GetPatientFormSignedUrlParams,
  GetPatientFormSignedUrlResponse,
  GetAppointmentFormSignedUrlParams,
  GetAppointmentFormSignedUrlResponse,
  ConfirmAppointmentParams,
  ConfirmAppointmentResponse,
  MarkAppointmentOfferingAsReadingParams,
  MarkAppointmentOfferingAsReadingResponse,
  GetCenterAppCustomerAuthCodeParams,
  GetCenterAppCustomerAuthCodeResponse,
  CenterAppCheckInAppointmentParams,
  CenterAppCheckInAppointmentResponse,
  ResendAppointmentReportParams,
  ResendAppointmentReportResponse,
  GenerateReportSignedUrlParams,
  GenerateReportSignedUrlResponse,
  MarkAppointmentReportUnexpectedFindingsParams,
  MarkAppointmentReportUnexpectedFindingsResponse,
  ConfirmAppointmentReportReceiptParams,
  ConfirmAppointmentReportReceiptResponse,
  RegisterCenterInMwlParams,
  RegisterCenterInMwlResponse,
  EditAppointmentTechnologistNoteParams,
  EditAppointmentTechnologistNoteResponse,
  AdjustAppointmentPriceParams,
  AdjustAppointmentPriceResponse,
  AddAppointmentRefundParams,
  AddAppointmentRefundResponse,
} from "@Shape-Digital/kudzu-data/lib/types/actions";

import { FUNCTIONS_PREFIX } from "../common/constants";

type FunctionResource = {
  createCustomCenterUserToken: [
    CreateCustomCenterUserTokenParams,
    CreateCustomCenterUserTokenResponse,
  ];
  addCenterTimeSlot: [AddCenterTimeSlotParams, AddCenterTimeSlotResponse];
  editCenterTimeSlot: [EditCenterTimeSlotParams, EditCenterTimeSlotResponse];
  deleteCenterTimeSlot: [
    DeleteCenterTimeSlotParams,
    DeleteCenterTimeSlotResponse,
  ];
  updateLastUsedRole: [UpdateLastUsedRoleParams, UpdateLastUsedRoleResponse];
  cancelAppointment: [CancelAppointmentParams, CancelAppointmentResponse];
  createAppointmentNote: [
    CreateAppointmentNoteParams,
    CreateAppointmentNoteResponse,
  ];
  rescheduleAppointment: [
    RescheduleAppointmentParams,
    RescheduleAppointmentResponse,
  ];
  updateCenterPatient: [UpdateCenterPatientParams, UpdateCenterPatientResponse];
  updateCenterAddress: [UpdateCenterAddressParams, UpdateCenterAddressResponse];
  updateCenterContactInformation: [
    UpdateCenterContactInformationParams,
    UpdateCenterContactInformationResponse,
  ];
  updateCenterOperatingHours: [
    UpdateCenterOperatingHoursParams,
    UpdateCenterOperatingHoursResponse,
  ];
  updateCenterDiscount: [
    UpdateCenterDiscountParams,
    UpdateCenterDiscountResponse,
  ];
  updateCenterOffering: [
    UpdateCenterOfferingParams,
    UpdateCenterOfferingResponse,
  ];
  addCenterOffering: [AddCenterOfferingParams, AddCenterOfferingResponse];
  addCenterAddOn: [AddCenterAddOnParams, AddCenterAddOnResponse];
  updateCenterAddOn: [UpdateCenterAddOnParams, UpdateCenterAddOnResponse];
  archiveCenterOffering: [
    ArchiveCenterOfferingParams,
    ArchiveCenterOfferingResponse,
  ];
  archiveCenterAddOn: [ArchiveCenterAddOnParams, ArchiveCenterAddOnResponse];
  addAppointmentFiles: [AddAppointmentFilesParams, AddAppointmentFilesResponse];
  sendCodePatientToUpdateEmail: [
    SendCodePatientToUpdateEmailParams,
    SendCodePatientToUpdateEmailResponse,
  ];
  addCenterPackage: [AddCenterPackageParams, AddCenterPackageResponse];
  updateCenterPackage: [UpdateCenterPackageParams, UpdateCenterPackageResponse];
  updateAppointmentOffering: [
    UpdateAppointmentOfferingParams,
    UpdateAppointmentOfferingResponse,
  ];
  markAppointmentOfferingAdditionalView: [
    MarkAppointmentOfferingAdditionalViewParams,
    MarkAppointmentOfferingAdditionalViewResponse,
  ];
  archiveCenterPackage: [
    ArchiveCenterPackageParams,
    ArchiveCenterPackageResponse,
  ];
  markAppointmentPriority: [
    MarkAppointmentPriorityParams,
    MarkAppointmentPriorityResponse,
  ];
  markAppointmentAsNoShow: [
    MarkAppointmentAsNoShowParams,
    MarkAppointmentAsNoShowResponse,
  ];
  getCustomerPaymentMethods: [
    GetCustomerPaymentMethodsParams,
    GetCustomerPaymentMethodsResponse,
  ];
  bookAdditionalViewsAppointment: [
    BookAdditionalViewAppointmentParams,
    BookAdditionalViewAppointmentResponse,
  ];
  payAppointmentRemainingBalance: [
    PayAppointmentRemainingBalanceParams,
    PayAppointmentRemainingBalanceResponse,
  ];
  sendCodeCustomerToUpdateEmail: [
    SendCodeCustomerToUpdateEmailParams,
    SendCodeCustomerToUpdateEmailResponse,
  ];
  updateCenterCustomer: [
    UpdateCenterCustomerParams,
    UpdateCenterCustomerResponse,
  ];
  provideCenterAppAppointmentScreeningForm: [
    ProvideCenterAppAppointmentScreeningFormParams,
    ProvideCenterAppAppointmentScreeningFormResponse,
  ];
  getPatientFormSignedUrl: [
    GetPatientFormSignedUrlParams,
    GetPatientFormSignedUrlResponse,
  ];
  getAppointmentFormSignedUrl: [
    GetAppointmentFormSignedUrlParams,
    GetAppointmentFormSignedUrlResponse,
  ];
  markAppointmentOfferingAsReading: [
    MarkAppointmentOfferingAsReadingParams,
    MarkAppointmentOfferingAsReadingResponse,
  ];
  getCenterAppCustomerAuthCode: [
    GetCenterAppCustomerAuthCodeParams,
    GetCenterAppCustomerAuthCodeResponse,
  ];
  confirmAppointment: [ConfirmAppointmentParams, ConfirmAppointmentResponse];
  centerAppCheckInAppointment: [
    CenterAppCheckInAppointmentParams,
    CenterAppCheckInAppointmentResponse,
  ];
  generateReportSignedUrl: [
    GenerateReportSignedUrlParams,
    GenerateReportSignedUrlResponse,
  ];
  resendAppointmentReport: [
    ResendAppointmentReportParams,
    ResendAppointmentReportResponse,
  ];
  markAppointmentReportUnexpectedFindings: [
    MarkAppointmentReportUnexpectedFindingsParams,
    MarkAppointmentReportUnexpectedFindingsResponse,
  ];
  confirmAppointmentReportReceipt: [
    ConfirmAppointmentReportReceiptParams,
    ConfirmAppointmentReportReceiptResponse,
  ];
  registerCenterInMwl: [RegisterCenterInMwlParams, RegisterCenterInMwlResponse];
  editAppointmentTechnologistNote: [
    EditAppointmentTechnologistNoteParams,
    EditAppointmentTechnologistNoteResponse,
  ];
  adjustAppointmentPrice: [
    AdjustAppointmentPriceParams,
    AdjustAppointmentPriceResponse,
  ];
  addAppointmentRefund: [
    AddAppointmentRefundParams,
    AddAppointmentRefundResponse,
  ];
};

type FunctionReq<K extends keyof FunctionResource> = FunctionResource[K][0];
type FunctionRes<K extends keyof FunctionResource> = FunctionResource[K][1];

const useFirebaseAppFunction = <K extends keyof FunctionResource>(
  functionName: K,
) => {
  const functions = useFunctions();

  const func = useMemo(() => {
    return httpsCallable<FunctionReq<K>, FunctionRes<K>>(
      functions,
      `${FUNCTIONS_PREFIX}-${functionName}`,
    );
  }, [functionName, functions]);

  return func;
};

export default useFirebaseAppFunction;
