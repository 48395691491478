import { GridColDef } from "../../../Unknown/DataGrid";
import { AppointmentPayment } from "../types";

type ColumnTranslations = {
  headerPaidBy: string;
};

const getColumnPaidBy = (params: {
  translations: ColumnTranslations;
  headerClassName: string;
}): GridColDef<AppointmentPayment, string> => {
  const { translations, headerClassName } = params;

  return {
    field: "customerName",
    disableColumnMenu: true,
    sortable: false,
    headerName: translations.headerPaidBy,
    flex: 1,
    headerClassName,
  };
};

export default getColumnPaidBy;
