import React, { FC, useState } from "react";
import { useFormikContext } from "formik";
import { Elements } from "@stripe/react-stripe-js";
import StatusDialog from "../../Unknown/StatusDialog";
import AppointmentPaymentAddForm, {
  AppointmentPaymentAddFormProps,
  PaymentOptionsData,
} from "../AppointmentPaymentAddForm";
import useTranslations from "./useTranslations";
import { FormValues } from "../AppointmentPaymentAddForm/types";
import useActionButtons from "./useActionButtons";
import { Appointment } from "../AppointmentPaymentListCardContainer/types";
import stripePromise from "../../../common/stripePromise";
import StripePaymentElement from "../../Stripe/StripePaymentElement";

export type AppointmentPaymentAddDialogProps = {
  appointment: Appointment | null;
  currencyCode: string;
  paymentOptionsData: PaymentOptionsData;
  formTranslations: AppointmentPaymentAddFormProps["translations"];
  errorMessage?: string | null;
  isOpen: boolean;
  stripeClientSecret: string | null;
  isRefundDue: boolean;
  onStripeElementClose: () => void;
  handleClose: () => void;
};

const AppointmentPaymentAddDialog: FC<AppointmentPaymentAddDialogProps> = ({
  appointment,
  isOpen,
  formTranslations,
  errorMessage,
  currencyCode,
  paymentOptionsData,
  stripeClientSecret,
  isRefundDue,
  onStripeElementClose,
  handleClose,
}) => {
  const [stripeError, setStripeError] = useState<string | null>(null);
  const { translations } = useTranslations();

  const { isSubmitting, resetForm, handleSubmit } =
    useFormikContext<FormValues>();

  const onClose = () => {
    handleClose();
    resetForm();
  };

  const handleStripeElementClose = () => {
    onStripeElementClose();
    setStripeError(null);
  };

  const actionButtons = useActionButtons({
    handleClose: onClose,
    handleSubmit,
    isLoading: isSubmitting,
    errorMessage,
  });

  const paymentIntentActionButtons = useActionButtons({
    handleClose: handleStripeElementClose,
    isLoading: isSubmitting,
    errorMessage,
  });

  const onPaymentError = (error: string) => setStripeError(error);

  return (
    <StatusDialog
      open={isOpen}
      title={translations.title}
      actionButtons={actionButtons}
      errorMessage={errorMessage}
      isLoading={isSubmitting}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          maxWidth: 800,
        },
      }}
    >
      <AppointmentPaymentAddForm
        translations={formTranslations}
        appointment={appointment}
        currencyCode={currencyCode}
        isRefundDue={isRefundDue}
        paymentOptionsData={paymentOptionsData}
      />
      {!!stripeClientSecret && (
        <StatusDialog
          open={!!stripeClientSecret}
          actionButtons={paymentIntentActionButtons}
          onClose={handleStripeElementClose}
          errorMessage={stripeError}
          fullWidth
          maxWidth="md"
          PaperProps={{
            sx: {
              maxWidth: 800,
            },
          }}
        >
          <Elements
            stripe={stripePromise}
            options={{ clientSecret: stripeClientSecret }}
          >
            <StripePaymentElement onError={onPaymentError} />
          </Elements>
        </StatusDialog>
      )}
    </StatusDialog>
  );
};

export default AppointmentPaymentAddDialog;
