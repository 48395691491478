import React, { FC } from "react";
import { useFormikContext } from "formik";
import { useIntl } from "react-intl";

import getFormattedPrice from "../../../common/getFormattedPrice";
import FormikTextField from "../../Unknown/FormikTextField";
import Typography from "../../Unknown/Typography";
import Grid from "../../Unknown/Grid";

import { FormValues, Translations } from "./types";
import useStyles from "./useStyles";
import { names } from "./constants";

export type AppointmentPaymentRefundFormProps = {
  translations: Translations;
  currencyCode: string;
  refundDue: number;
  refundLimit: number;
};

const AppointmentPaymentRefundForm: FC<AppointmentPaymentRefundFormProps> = ({
  translations,
  currencyCode,
  refundDue,
  refundLimit,
}) => {
  const { isSubmitting } = useFormikContext<FormValues>();
  const classes = useStyles();
  const intl = useIntl();

  const formattedRefundDue = getFormattedPrice({
    value: refundDue,
    currencyCode,
    intl,
  });

  const formattedRefundLimit = getFormattedPrice({
    value: refundLimit,
    currencyCode,
    intl,
  });

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <Typography classes={{ root: classes.labelRoot }}>
          {translations.refundDue}
        </Typography>
        <Typography variant="body2">{formattedRefundDue}</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography classes={{ root: classes.labelRoot }}>
          {translations.refundLimit}
        </Typography>
        <Typography variant="body2">{formattedRefundLimit}</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormikTextField
          name={names.amount}
          type="number"
          label={translations.amount}
          fullWidth
          disabled={isSubmitting}
          required
        />
      </Grid>
    </Grid>
  );
};

export default AppointmentPaymentRefundForm;
