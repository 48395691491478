import { FormikConfig } from "formik";
import { useParams } from "react-router-dom";

import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import { FormValues } from "../AppointmentPaymentRefundForm/types";
import useValidationSchema from "./useValidationSchema";
import useTranslations from "./useTranslations";

type FormikProps = FormikConfig<FormValues>;

type SubmitHandler = FormikConfig<FormValues>["onSubmit"];

const useFormikProps = (params: {
  maxAmount: number;
  appointmentPaymentId: string;
  setErrorMessage: (value: string | null) => void;
  handleAddRefund: () => void;
  handleClose: () => void;
}): FormikProps => {
  const {
    maxAmount,
    appointmentPaymentId,
    setErrorMessage,
    handleAddRefund,
    handleClose,
  } = params;

  const { appointmentId } = useParams();

  const { translations } = useTranslations({ maxAmount });

  const addAppointmentRefund = useFirebaseAppFunction("addAppointmentRefund");

  const initialValues = {
    amount: 0,
  };

  const validationSchema = useValidationSchema({ maxAmount });

  const onSubmit: SubmitHandler = async (values, { resetForm }) => {
    try {
      const { amount } = values;

      if (!appointmentId) return;

      await addAppointmentRefund({
        appointmentPaymentId,
        amount,
      });

      handleAddRefund();
      handleClose();
      resetForm();
    } catch (error) {
      const message =
        error instanceof Error ? error.message : translations.defaultError;
      setErrorMessage(message);
    }
  };

  return {
    initialValues,
    validationSchema,
    onSubmit,
  };
};

export default useFormikProps;
