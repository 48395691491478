import { defineMessages } from "react-intl";

const messages = defineMessages({
  headerRefund: {
    id: "AppointmentPayment.AppointmentRefundGrid.headerRefund",
    defaultMessage: "Refund",
  },
  headerRefunded: {
    id: "AppointmentPayment.AppointmentRefundGrid.headerRefunded",
    defaultMessage: "Refunded",
  },
  headerRefundedTo: {
    id: "AppointmentPayment.AppointmentRefundGrid.headerRefundedTo",
    defaultMessage: "Refunded to",
  },
  methodCard: {
    id: "AppointmentPayment.AppointmentRefundGrid.methodCard",
    defaultMessage: "Card",
  },
  methodCash: {
    id: "AppointmentPayment.AppointmentRefundGrid.methodCash",
    defaultMessage: "Cash",
  },
  btnRefund: {
    id: "AppointmentPayment.AppointmentRefundGrid.btnRefund",
    defaultMessage: "Refund",
  },
});

export default messages;
