import { useMemo } from "react";
import * as yup from "yup";
import useTranslations from "./useTranslations";

const useValidationSchema = ({
  currentPrice = 0,
}: {
  currentPrice?: number;
}) => {
  const { translations } = useTranslations();

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      reason: yup.string().trim().required(translations.reasonIsRequired),
      adjustmentType: yup.string().required(translations.typeIsRequired),
      amount: yup
        .number()
        .positive(translations.invalidAmount)
        .when("adjustmentType", {
          is: "decreased",
          then: yup.number().max(currentPrice, translations.invalidAmount),
          otherwise: yup.number(),
        })
        .required(translations.invalidAmount),
    });
  }, [currentPrice, translations]);

  return validationSchema;
};

export default useValidationSchema;
